<template>
   <v-app>
      <v-main>
        <div class="d-flex mx-auto">
          <div class="mx-auto">

            <div class="text-h1"> iMETAL</div>
          </div>

        </div>
         <v-container fluid fill-height class="pb-16">
            <v-layout align-center justify-center class="pb-16">
               <v-flex xs12 sm8 md4>
                  <div class="pb-10 d-flex pa-2 justify-center">
                    <v-img
                      lazy-src="../assets/img/logo.png"
                      max-height="150"
                      max-width="250"
                      src="../assets/img/logo.png"
                    ></v-img>
                  </div>
                  <v-card class="elevation-8 pt-20 mb-3">
                     <v-toolbar dark color="secondary">
                        <v-toolbar-title>Login</v-toolbar-title>
                     </v-toolbar>

                     <v-card-text>
                     <v-form ref="form" v-model="valid"  lazy-validation >
                            <v-text-field
                              v-model="username"
                              required
                              :rules="[ v => !!v ||'Username is required!']"
                              name="username"
                              label="Username"
                              type="text"
                              placeholder="username"
                              v-on:keyup.enter="login()"

                           ></v-text-field>
                            <v-text-field
                              v-model="password"
                              name="password"
                              label="Password"
                              :type="showPassword?'text':'password'"
                              placeholder="password"
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :rules="[ v => !!v ||'Password is required!']"
                              required
                              v-on:keyup.enter="login()"
                              @click:append="showPassword = !showPassword"
                           ></v-text-field>
                           <v-btn :disabled="!valid" class="mt-4 mb-4" @click="login()" color="primary" value="log in">Login</v-btn>
                      </v-form>
                     </v-card-text>
                  </v-card>
            <div class="pb-3 pt-n50 caption grey--text text--darken-1">© 2023. iMetal.co. All Rights Reserved. Contact: info@imetal.io </div>

               </v-flex>

            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
import { authenticate } from '@/api/users'
import {getUserSetting} from '@/api/user/userSetting.js'

export default {
  name: "Login",
  data() {
    return {
      valid: true,
      showPassword: false,
      username: "",
      password: "",
      confirmPassword: "",
      errorMessage: ""
    };
  },
  created(){
    this.$store.commit('darkModeState', false) 
  },
  methods: {
    login() {
      var isValidated = this.$refs.form.validate()
      if (isValidated){
          const _this = this
          authenticate(this.username, this.password, function (res){
            _this.$root.notify.show({message: 'Welcome ' + _this.username + "!"})
            _this.$store.commit('login',res)
            getUserSetting( (res) =>{
              const uiSetting = res.find(item => item.key === 'uiSetting')
              console.log(uiSetting)
              if(uiSetting) {
                  _this.$store.commit('darkModeState', uiSetting.value.darkMode) 
              }
            } )
            if( _this.$route.query.redirect != null){
              _this.$router.push(_this.$route.query.redirect);
            }else {
              if (res.is_staff === true){
                _this.$router.push('/admin');
              }else {
                _this.$router.push('/panel');
            }
            }
          }, function (err){
            _this.$root.notify.show({message: "Invalid username or password", type: "error"})
            console.log(err)
          } )

      }
        //  const _this = this

     //this.errorMessage = "password did not match"
    }
  },
  computed: {
  }
};
</script>